import {
  BottomSection,
  DesktopLayout,
  FlexRow,
  GrayPanel,
  HeadlineText,
  Left,
  Loading,
  Right,
  VerticalSpacing,
  SectionHeader as ogSectionHeader,
  Text as ogText,
} from "../StandardComponents";
import {
  DailyIncentiveLog,
  Dashboard,
  IncentiveMechanic,
  IncentiveSplitMechanic,
  SalesSummaryRowType,
  TlbgConcept,
  Venue,
} from "../../typings/common";
import {
  copywriting,
  dailySalesBarStartTextTemplate,
  dailySalesBarTargetTextTemplate,
  monthlySalesBarEndText2Template,
  monthlySalesBarEndTextTemplate,
  monthlySalesBarStartTextTemplate,
} from "./common";
import { defaultVenue, loggedInUser, refreshInterval } from "../../utils/globals";
import { formatCurrency, formatPercentage } from "../../utils/currency";
import {
  fromStandardDateFormat,
  toHumanDateFormat,
  toHumanDateTimeFormat,
  toStandardDateFormat,
} from "../../utils/date";

import IncentivesDashboardHeader from "../IncentivesDashboardHeader";
import IncentivesDashboardMobileMode from "./IncentivesDashboardMobile";
import LoadingOverlay from "react-loading-overlay";
import MainHeader from "../MainHeader";
import { Moment } from "moment";
import ProgressBar from "../ProgressBarNew";
import React from "react";
import SalesSummaryRow from "../SalesSummaryRow";
import { getAvailableVenues } from "../../services/AirtablePlus";
import { getIncentiveDashboardVals } from "./IncentivesController";
import moment from "moment";
import styled from "styled-components";

type SelectionProps = {
  venue: string;
  date: Moment;
};

const MOBILE_THRESHOLD_WIDTH = 600;

const EmojiText = styled(HeadlineText)`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EmojiFont = styled.p`
  text-align: center;
  font-size: 36px;
  margin: 0px;
`;

const Text = styled(ogText)`
  margin: 4px 0px;
`;

const BigSectionHeader = styled(ogSectionHeader)`
  margin: 0px 0px 12px 0px;
  font-size: 20px;
  padding: 4px 4px 12px 0px;
`;

const SectionHeader = styled(ogSectionHeader)`
  margin: 0px 0px 12px;
  padding: 0px 0px 12px 0px;
`;

const MiddleSection = styled.div`
  width: 100%;
`;

const Section = styled.div`
  margin: 0px 0px 16px 0px;
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const dimensions = {
    width: width,
    height: height,
  };
  return dimensions;
};

const IncentivesDashboard: React.FC = (props) => {
  const [availableVenues, setAvailableVenues] = React.useState<Venue[]>();
  const [selection, setSelection] = React.useState<SelectionProps>();
  const [dashboardValues, setDashboardValues] = React.useState<DailyIncentiveLog>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = React.useState<{
    height: number;
    width: number;
  }>(getWindowDimensions());
  const [incentiveMechanic, setIncentiveMechanic] = React.useState<IncentiveMechanic>();
  const [incentiveSplitMechanic, setIncentiveSplitMechanic] =
    React.useState<IncentiveSplitMechanic>(IncentiveSplitMechanic.BY_STAFF);
  const [currentIntervalId, setCurrentIntervalId] = React.useState<NodeJS.Timeout>();

  async function fetchIncentiveDashboardVals(venueName: string, date: Moment) {
    setLoading(true);
    const mechanic = availableVenues?.find((venue) => venue.name === venueName)
      ?.incentive_mechanic as IncentiveMechanic;
    const splitMechanic = availableVenues?.find((venue) => venue.name === venueName)
      ?.incentive_split_mechanic as IncentiveSplitMechanic;
    getIncentiveDashboardVals(venueName, date, mechanic).then((dailyIncentiveLog) => {
      setDashboardValues(dailyIncentiveLog);
      setIncentiveMechanic(mechanic);
      setIncentiveSplitMechanic(splitMechanic);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    setLoading(true);
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);

    getAvailableVenues(Dashboard.incentives, loggedInUser()).then((availableVenuesResponse) => {
      setAvailableVenues(availableVenuesResponse);
      const mechanic = availableVenuesResponse?.find((venue) => venue.name === defaultVenue())
        ?.incentive_mechanic as IncentiveMechanic;
      setIncentiveMechanic(mechanic);
      setSelection({
        venue: defaultVenue(),
        date: moment(),
      });
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (selection) {
      fetchIncentiveDashboardVals(selection.venue, selection.date);

      // Remove previous refresh logic
      if (currentIntervalId !== undefined) {
        clearInterval(currentIntervalId);
      }

      // Set up refresh logic
      const newIntervalId = setInterval(() => {
        if (selection?.date.isSame(moment(), "day")) {
          fetchIncentiveDashboardVals(selection?.venue ?? "", moment());
        }
      }, refreshInterval);
      setCurrentIntervalId(newIntervalId);
    }
  }, [selection]);

  if (windowDimensions.width < MOBILE_THRESHOLD_WIDTH) {
    return (
      <IncentivesDashboardMobileMode
        selection={{
          date: toStandardDateFormat(selection?.date ?? moment()),
          venue: selection?.venue ?? "",
        }}
        {...{
          availableVenues,
          dashboardValues,
          loading,
          incentiveMechanic,
          incentiveSplitMechanic,
        }}
        selectionChangeHandler={(selected) =>
          setSelection({ venue: selected.venue, date: fromStandardDateFormat(selected.date) })
        }
      />
    );
  } else {
    return (
      <LoadingOverlay
        active={loading}
        spinner={Loading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            background: "rgba(100, 108, 119, 0.2)",
            zIndex: 2000,
            display: "flex",
            flex: 1,
            alignItems: "stretch",
            overflow: "hidden",
          }),
        }}
      >
        <Wrapper>
          <IncentivesDashboardHeader
            venueOptions={availableVenues}
            venueChangeHandler={(selectedVenue) =>
              setSelection({
                date: selection?.date ?? moment(),
                venue: selectedVenue,
              })
            }
            venueSelected={selection?.venue}
            venuePlaceholder="Select venue"
            dateType="day"
            dateChangeHandler={(date) =>
              setSelection({
                venue: selection?.venue ?? "",
                date: moment(date as Date),
              })
            }
            dateSelected={selection?.date.toDate()}
            menuOuterContainerId="outer-container"
            menuSelectedItem="Incentives"
            outerContainerId="outer-container"
            context={Dashboard.incentives}
          />

          <DesktopLayout scrollable={false}>
            <FlexRow marginBottom={16}>
              <Left zIndex={1001}>
                <HeadlineText>
                  {(incentiveMechanic === "monthlyPot" ||
                    incentiveMechanic === "monthlyPot3Tier")
                    ? "Monthly "
                    : "Daily "}
                  sales incentive for {toHumanDateFormat(selection?.date ?? "")}
                </HeadlineText>
              </Left>
              <MainHeader secondaryLogo={selection?.venue as TlbgConcept} isFixed={false} />
              <Right zIndex={1001} flex={1}>
                <Text>
                  Last updated:{" "}
                  {dashboardValues?.lastUpdated !== undefined
                    ? toHumanDateTimeFormat(dashboardValues?.lastUpdated)
                    : "(Not updated)"}
                </Text>
              </Right>
            </FlexRow>

            <MiddleSection>
              <BigSectionHeader>
                {copywriting.title[incentiveMechanic ?? "monthlyPotWithDailyAddition"]}
              </BigSectionHeader>
              <VerticalSpacing height={8} />
              {incentiveMechanic === "monthlyPotWithDailyAddition" && (
                <ProgressBar
                  progress={dashboardValues?.dailySales ?? 0}
                  target={dashboardValues?.dailyTarget ?? 0}
                  end={2 * (dashboardValues?.dailyTarget ?? 0)}
                  barHeight={windowDimensions.height * 0.1}
                  startText={dailySalesBarStartTextTemplate}
                  targetText={dailySalesBarTargetTextTemplate}
                />
              )}
              {(incentiveMechanic === "monthlyPot" ||
                incentiveMechanic === "monthlyPot3Tier") && (
                  <ProgressBar
                    progress={dashboardValues?.monthlySales ?? 0}
                    target={dashboardValues?.monthlyTarget ?? 0}
                    end={2 * (dashboardValues?.monthlyTarget ?? 0)}
                    barHeight={windowDimensions.height * 0.1}
                    startText={monthlySalesBarStartTextTemplate}
                    targetText={monthlySalesBarEndTextTemplate}
                    target2={dashboardValues?.monthlyTarget2}
                    target2Text={monthlySalesBarEndText2Template}
                    z={dashboardValues?.monthlyTargetBonusCut2}
                  />
                )}
              <VerticalSpacing height={8} />
            </MiddleSection>

            <BottomSection>
              {incentiveMechanic === "monthlyPotWithDailyAddition" && (
                <>
                  <GrayPanel flex={2}>
                    <SectionHeader>{copywriting.potProgressSection.title}</SectionHeader>
                    <VerticalSpacing height={8} />
                    <ProgressBar
                      progress={dashboardValues?.monthlySales ?? 0}
                      target={dashboardValues?.monthlyTarget ?? 0}
                      end={dashboardValues?.monthlyTarget ?? 0}
                      barHeight={windowDimensions.height * 0.08}
                      startText={monthlySalesBarStartTextTemplate}
                      endTopText={monthlySalesBarEndTextTemplate}
                      includeTargetLine={false}
                      outline
                    />
                    <VerticalSpacing height={8} />
                    <EmojiFont>
                      {dashboardValues?.isPotUnlocked
                        ? copywriting.potProgressSection.potUnlocked.emoji
                        : copywriting.potProgressSection.potNotUnlocked.emoji}
                    </EmojiFont>
                    <EmojiText>
                      {dashboardValues?.isPotUnlocked
                        ? copywriting.potProgressSection.potUnlocked.text
                        : copywriting.potProgressSection.potNotUnlocked.text}
                    </EmojiText>
                  </GrayPanel>

                  <GrayPanel flex={2}>
                    <SectionHeader>{copywriting.potSection.title}</SectionHeader>

                    <Section>
                      <SalesSummaryRow
                        itemNameText={
                          copywriting.potSection.monthlyPotWithDailyAddition.todaysAdditionToPot
                        }
                        itemValueText={formatCurrency(
                          (dashboardValues?.additionPreTargetPortion ?? 0) +
                          (dashboardValues?.additionPostTargetPortion ?? 0)
                        )}
                        type={SalesSummaryRowType.headline}
                      />

                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.preTargetText(
                          dashboardValues?.dailyTargetCut,
                          dashboardValues?.dailyTarget
                        )}
                        itemValueText={formatCurrency(dashboardValues?.additionPreTargetPortion)}
                        type={SalesSummaryRowType.grayText}
                      />

                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.postTargetText(
                          dashboardValues?.dailyTargetBonusCut,
                          dashboardValues?.dailyTarget
                        )}
                        itemValueText={formatCurrency(dashboardValues?.additionPostTargetPortion)}
                        type={SalesSummaryRowType.grayText}
                      />
                    </Section>

                    <Section>
                      <SalesSummaryRow
                        itemNameText={
                          copywriting.potSection.monthlyPotWithDailyAddition.potSizeText
                        }
                        itemValueText={formatCurrency(dashboardValues?.potAfterAddition)}
                        type={SalesSummaryRowType.grayText}
                      />

                      {incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF && (
                        <SalesSummaryRow
                          itemNameText={copywriting.potSection.staffCountText(incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "points")}
                          itemValueText={dashboardValues?.numberToSplit?.toFixed(1) ?? "-"}
                          type={SalesSummaryRowType.grayText}
                        />
                      )}

                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.potRewardText(
                          incentiveMechanic,
                          dashboardValues?.isPotUnlocked ?? false,
                          incentiveSplitMechanic
                        )}
                        itemValueText={formatCurrency(dashboardValues?.potReward)}
                        type={SalesSummaryRowType.blueBoldTextWSummaryLine}
                      />
                    </Section>
                  </GrayPanel>
                </>
              )}
              {(incentiveMechanic === "monthlyPot" ||
                incentiveMechanic === "monthlyPot3Tier") && (
                  <GrayPanel flex={3}>
                    <SectionHeader>The pot</SectionHeader>

                    <Section>
                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.monthlyPot.title}
                        itemValueText={
                          dashboardValues?.isPotUnlocked
                            ? `${copywriting.potProgressSection.potUnlocked.text} ${copywriting.potProgressSection.potUnlocked.emoji}`
                            : `${copywriting.potProgressSection.potNotUnlocked.text} ${copywriting.potProgressSection.potNotUnlocked.emoji}`
                        }
                        type={SalesSummaryRowType.blueBoldText}
                      />

                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.monthlyPot.potSizeText(
                          dashboardValues?.isPotUnlocked
                        )}
                        itemValueText={formatCurrency(
                          (dashboardValues?.preTargetPortion ?? 0) +
                          (dashboardValues?.postTargetPortion ?? 0)
                        )}
                        type={SalesSummaryRowType.blueBoldTextWSummaryLine}
                      />

                      <SalesSummaryRow
                        // itemNameText={`${formatPercentage(
                        //   dashboardValues?.monthlyTargetCut,
                        //   2
                        // )} of first ${formatCurrency(dashboardValues?.monthlyTarget)}`}
                        itemNameText={copywriting.potSection.preTargetText(
                          dashboardValues?.monthlyTargetCut,
                          dashboardValues?.monthlyTarget
                        )}
                        itemValueText={formatCurrency(dashboardValues?.preTargetPortion)}
                        type={SalesSummaryRowType.grayText}
                      />

                      <SalesSummaryRow
                        itemNameText={copywriting.potSection.postTargetText(
                          dashboardValues?.monthlyTargetBonusCut,
                          dashboardValues?.monthlyTarget
                        )}
                        itemValueText={dashboardValues?.postTargetPortion2 > 0 ? "-" : formatCurrency(dashboardValues?.postTargetPortion)}
                        type={SalesSummaryRowType.grayText}
                      />

                      {incentiveMechanic === "monthlyPot3Tier" && (
                        <SalesSummaryRow
                          itemNameText={copywriting.potSection.postTarget2Text(dashboardValues?.monthlyTargetBonusCut2, dashboardValues?.monthlyTarget)}
                          itemValueText={formatCurrency(dashboardValues?.postTargetPortion2)}
                          type={SalesSummaryRowType.grayText}
                        />
                      )}
                    </Section>

                    <Section>
                      <SalesSummaryRow
                        itemNameText={`How many ${incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "points"} to split`}
                        itemValueText={dashboardValues?.numberToSplit?.toFixed(2) ?? "-"}
                        type={SalesSummaryRowType.grayText}
                      />

                      <SalesSummaryRow
                        itemNameText={
                          dashboardValues?.isPotUnlocked
                            ? `Each ${incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "point"} will get`
                            : `When we hit our target, each ${incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "point"} will get`
                        }
                        itemValueText={formatCurrency(dashboardValues?.potReward)}
                        type={SalesSummaryRowType.blueBoldTextWSummaryLine}
                      />
                    </Section>
                  </GrayPanel>
                )}

              <GrayPanel flex={3}>
                <SectionHeader>{copywriting.howItWorksSection.title}</SectionHeader>
                {incentiveMechanic === "monthlyPotWithDailyAddition" && (
                  <ul>
                    {copywriting.howItWorksSection
                      .monthlyPotWithDailyAddition(
                        dashboardValues?.dailyTargetCut,
                        dashboardValues?.dailyTargetBonusCut
                      )
                      .map((copyLine, i) => (
                        <li key={i}>{copyLine}</li>
                      ))}
                  </ul>
                )}
                {incentiveMechanic === "monthlyPot" && (
                  <ul>
                    {copywriting.howItWorksSection
                      .monthlyPot(
                        dashboardValues?.monthlyTargetCut,
                        dashboardValues?.monthlyTargetBonusCut,
                        selection?.venue === "Fico" ? 6 : 5
                      )
                      .map((copyLine, i) => (
                        <li key={i}>{copyLine}</li>
                      ))}
                  </ul>
                )}
                {
                  incentiveMechanic === "monthlyPot3Tier" && (
                    <ul>
                      {copywriting.howItWorksSection
                        .monthlyPot3Tier(
                          dashboardValues?.monthlyTargetCut,
                          dashboardValues?.monthlyTargetBonusCut,
                          dashboardValues?.monthlyTargetBonusCut2,
                          selection?.venue === "Fico" ? 6 : 5
                        )
                        .map((copyLine, i) => (
                          <li key={i}>{copyLine}</li>
                        ))}
                    </ul>
                  )
                }
              </GrayPanel>
            </BottomSection>
          </DesktopLayout>
        </Wrapper>
      </LoadingOverlay>
    );
  }
};

export default IncentivesDashboard;
