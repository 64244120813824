import { formatCurrency, formatPercentage } from "../../utils/currency";

import { IncentiveSplitMechanic } from "../../typings/common";

const dailySalesBarStartTextTemplate = (
  progress: number,
  end: number,
  start?: number,
  target?: number
) =>
  `Today's sales: ${formatCurrency(progress)} ` +
  `(${target ? formatPercentage(progress / target) : ""} of target)`;

const dailySalesBarTargetTextTemplate = (
  progress: number,
  end: number,
  start?: number,
  target?: number
) => `Today's target: ${formatCurrency(target)}`;

const monthlySalesBarStartTextTemplate = (
  progress: number,
  end: number,
  start?: number,
  target?: number
) =>
  `Sales this month: ${formatCurrency(progress)} ` +
  `(${target ? formatPercentage(progress / target) : ""} of target)`;

const monthlySalesBarEndTextTemplate = (
  progress: number,
  end: number,
  start?: number,
  target?: number
) => `Monthly sales\nto unlock pot:\n${formatCurrency(target)}`;

const monthlySalesBarEndText2Template = (z: number, target2: number) =>
  `Monthly sales\nto unlock ${z * 100}%:\n${formatCurrency(target2)}`;

const copywriting = {
  title: {
    monthlyPotWithDailyAddition: "Daily sales target tracker",
    monthlyPot: "Monthly sales target tracker",
    monthlyPot3Tier: "Monthly sales target tracker",
  },
  potProgressSection: {
    title: "Have we unlocked the pot?",
    potUnlocked: {
      emoji: "🎉",
      text: "Yes! Well done!",
    },
    potNotUnlocked: {
      emoji: "😥",
      text: "Not yet! Keep at it!",
    },
  },
  potSection: {
    title: "The pot",
    monthlyPotWithDailyAddition: {
      todaysAdditionToPot: "Today's addition to pot",
      potSizeText: "How big is the pot so far",
    },
    monthlyPot: {
      title: "Have we hit our target?",
      potSizeText: (isPotUnlocked?: boolean) =>
        isPotUnlocked ? "The pot so far" : "The pot if we hit our target",
    },
    preTargetText: (x?: number, target?: number) =>
      `${formatPercentage(x, 2)} of first ${formatCurrency(target)}`,
    postTargetText: (y?: number, target?: number) =>
      `${formatPercentage(y, 2)} of sales above ${formatCurrency(target)}`,
    postTarget2Text: (z?: number, target?: number) =>
      `${formatPercentage(z, 2)} of sales above ${target ? formatCurrency(target) : "$∞"}`,
    staffCountText: (unit: string) => `How many ${unit} to split`,
    potRewardText: (
      incentiveMechanic: "monthlyPotWithDailyAddition" | "monthlyPot" | "monthlyPot3Tier",
      isPotUnlocked: boolean,
      incentiveSplitMechanic: IncentiveSplitMechanic
    ) => {
      const entityToSplitString =
        incentiveSplitMechanic === IncentiveSplitMechanic.BY_POINT ? "point" : "staff";
      return incentiveMechanic === "monthlyPotWithDailyAddition"
        ? `When pot unlocks, each ${entityToSplitString} will get`
        : isPotUnlocked
        ? `Each ${entityToSplitString} will get`
        : `When we hit our target, each ${entityToSplitString} will get`;
    },
  },
  howItWorksSection: {
    title: "How it works",
    monthlyPotWithDailyAddition: (x?: number, y?: number) => [
      "There are daily sales target for each day of the week",
      `If you hit the target, ${formatPercentage(x, 2)} of the sales will go into a pot`,
      `If you exceed the target, ${formatPercentage(
        y,
        2
      )} of all sales above the target goes into the pot`,
      "You unlock the pot once you hit a monthly sales target",
      "The pot will be split equally amongst all staff (For all staff except Grade 6 and above)",
      "Finance will make an end of month adjustments for refunds before finalising payout",
      "The pot will be paid out on the end of the following month 🙂",
    ],
    monthlyPot: (
      x?: number,
      y?: number,
      gradeExclusionThreshold = 5 // Grade where above which (inclusive) staff will not be eligible for pot
    ) => [
      "Each month, there will be a monthly sales target",
      `If you hit the target, ${formatPercentage(x, 2)} of the sales will go into a pot`,
      `If you exceed the target, ${formatPercentage(
        y,
        2
      )} of all sales above the target goes into the pot`,
      `The pot will be split equally amongst all staff (For all staff except Grade ${gradeExclusionThreshold.toString()} and above)`,
      "Finance will make an end of month adjustments for refunds before finalising payout",
      "The pot will be paid out on the end of the following month 🙂",
    ],
    monthlyPot3Tier: (
      x?: number,
      y?: number,
      z?: number,
      gradeExclusionThreshold = 5 // Grade where above which (inclusive) staff will not be eligible for pot
    ) => [
      "Each month, there will be two monthly sales targets – a lower and a higher",
      `If you hit the lower target, ${formatPercentage(x, 2)} of the sales will go into a pot`,
      `If you exceed the lower target but not the higher target, ${formatPercentage(
        y,
        2
      )} of all sales above the lower target goes into the pot`,
      `If you exceed the higher target, ${formatPercentage(
        z,
        2
      )} of all sales above the lower target goes into the pot`,
      `The pot will be split equally amongst all staff (For all staff except Grade ${gradeExclusionThreshold.toString()} and above)`,
      "Finance will make an end of month adjustments for refunds before finalising payout",
      "The pot will be paid out on the end of the following month 🙂",
    ],
  },
};

export {
  dailySalesBarStartTextTemplate,
  dailySalesBarTargetTextTemplate,
  monthlySalesBarStartTextTemplate,
  monthlySalesBarEndTextTemplate,
  monthlySalesBarEndText2Template,
  copywriting,
};
