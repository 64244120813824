import React from "react";
import styled from "styled-components";
// import * from '../assets/concept_logo';

type LogoType = "header" | "thumbnail";

const logoEsr = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/ESR.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Esora"
  />
);

const logoEvp = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/EVP.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Extra Virgin Pizza"
  />
);

const logoLbf = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/LBF.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Le Bon Funk"
  />
);

const logoLoo = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/LOO.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Esora"
  />
);

const logoTbc = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/TBC.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Tanjong Beach Club"
  />
);

const logoTbs = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/TBS.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="The Black Swan"
  />
);

const logoTwr = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/TWR.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="The White Rabbit"
  />
);

const logoOef = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/OEF.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
  />
);

const logoOdt = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/ODT.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Odette"
  />
);

const logoStc = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/STC.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Straits Clan"
  />
);

const logoTrd = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/old/TRD.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="The Red Dragon"
  />
);

const logoTcc = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/TCC.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="The Coconut Club"
  />
);

const logoCld = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/CLD.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Claudine"
  />
);

const logoFic = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/FIC.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Fico"
  />
);

const logoBbf = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/BBF.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Bar Bon Funk"
  />
);

const logoSma = (type: LogoType) => (
  <img
    src={require("../assets/concept_logo/SMA.png")}
    className={type === "header" ? "header-concept-logo" : "thumbnail-concept-logo"}
    alt="Somma"
  />
);

const ConceptLogo: {
  [key: string]: any;
} = {
  ESR: logoEsr,
  EVP: logoEvp,
  LBF: logoLbf,
  LOO: logoLoo,
  TBC: logoTbc,
  TBS: logoTbs,
  TWR: logoTwr,
  OEF: logoOef,
  ODT: logoOdt,
  STC: logoStc,
  TRD: logoTrd,
  GRK: logoEvp,
  CCS: logoTcc,
  CLD: logoCld,
  CCA: logoTcc,
  CCB: logoTcc,
  CCR: logoTcc,
  FHV: logoLbf,
  FIC: logoFic,
  BBF: logoBbf,
  SMA: logoSma,
};

export default ConceptLogo;
