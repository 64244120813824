import CryptoJS from "crypto-js";
const NUM_OF_BYTES = 22; // Total of 44 characters (1 Bytes = 2 char) (standard states that: 43 chars <= verifier <= 128 chars)
const HASH_ALG = "sha256";

const generatePkcePair = () => {
  const randomVerifier = CryptoJS.lib.WordArray.random(NUM_OF_BYTES).toString(CryptoJS.enc.Hex);
  const hash = CryptoJS.SHA256(randomVerifier).toString(CryptoJS.enc.Base64);
  const challenge = hash.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, ""); // Clean base64 to make it URL safe
  return { verifier: randomVerifier, challenge };
};

export default generatePkcePair;
