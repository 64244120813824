import {
  DailyIncentiveLog,
  IncentiveMechanic,
  IncentiveSplitMechanic,
  SalesSummaryRowType,
  TlbgConcept,
  Venue,
} from "../../typings/common";
import { Loading, MobileLayout, Section, SectionHeader } from "../StandardComponents";
import {
  copywriting,
  dailySalesBarStartTextTemplate,
  dailySalesBarTargetTextTemplate,
  monthlySalesBarEndText2Template,
  monthlySalesBarEndTextTemplate,
  monthlySalesBarStartTextTemplate,
} from "./common";

import { FC } from "react";
import LoadingOverlay from "react-loading-overlay";
import MainHeader from "../MainHeader";
import ProgressBar from "../ProgressBarNew";
import React from "react";
import SalesSummaryRow from "../SalesSummaryRow";
import SelectField from "../InputFields/SelectField";
import SideBar from "../SideBarNew";
import TextField from "../InputFields/TextField";
import { formatCurrency, formatPercentage } from "../../utils/currency";
import { fromStandardDateFormat } from "../../utils/date";
import styled from "styled-components";

type IncentivesDashboardMobileModeSelection = {
  venue: string;
  date: string;
};

type IncentivesDashboardMobileModeProps = {
  selection: IncentivesDashboardMobileModeSelection;
  selectionChangeHandler: (selected: IncentivesDashboardMobileModeSelection) => void;
  availableVenues?: Venue[];
  dashboardValues?: DailyIncentiveLog;
  loading: boolean;
  incentiveMechanic?: IncentiveMechanic;
  incentiveSplitMechanic?: IncentiveSplitMechanic;
  currentIntervalId?: number;
};

const BAR_HEIGHT = 48;

const IncentivesDashboardMobileMode: FC<IncentivesDashboardMobileModeProps> = ({
  selection,
  selectionChangeHandler,
  availableVenues = [],
  dashboardValues,
  loading,
  incentiveMechanic = "monthlyPot",
  incentiveSplitMechanic = IncentiveSplitMechanic.BY_STAFF,
}) => {
  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          position: "fixed",
          height: "100vh",
        }),
      }}
    >
      <MobileLayout>
        <MainHeader secondaryLogo={selection?.venue as TlbgConcept} />

        <SideBar selectedItem="Incentives" />

        <SelectField
          label="Venue"
          placeholderLabel="Select venue..."
          changeHandler={(selectedVenue) => {
            selectionChangeHandler({
              ...selection,
              venue: selectedVenue,
            });
          }}
          defaultValue={selection?.venue}
          options={availableVenues
            ?.filter((venue) => venue.name !== undefined)
            .map((venue) => ({
              label: (venue.combined_entity_name as string) ?? (venue.name as string),
              value: venue.name as string,
            }))}
        />

        <TextField
          defaultDate={
            selection?.date !== undefined
              ? fromStandardDateFormat(selection?.date).toDate()
              : undefined
          }
          label="Date"
          inputType="date"
          changeHandler={(selectedDate) => {
            selectionChangeHandler({
              ...selection,
              date: selectedDate,
            });
          }}
        />

        <SectionHeader>{copywriting.title[incentiveMechanic]}</SectionHeader>
        <Section>
          {incentiveMechanic === "monthlyPotWithDailyAddition" && (
            <ProgressBar
              progress={dashboardValues?.dailySales ?? 0}
              target={dashboardValues?.dailyTarget ?? 0}
              end={2 * (dashboardValues?.dailyTarget ?? 0)}
              barHeight={BAR_HEIGHT}
              startText={dailySalesBarStartTextTemplate}
              targetText={dailySalesBarTargetTextTemplate}
            />
          )}
          {(incentiveMechanic === "monthlyPot" ||
            incentiveMechanic === "monthlyPot3Tier") && (
              <ProgressBar
                progress={dashboardValues?.monthlySales ?? 0}
                target={dashboardValues?.monthlyTarget ?? 0}
                end={2 * (dashboardValues?.monthlyTarget ?? 0)}
                barHeight={BAR_HEIGHT}
                startText={monthlySalesBarStartTextTemplate}
                targetText={monthlySalesBarEndTextTemplate}
                target2={dashboardValues?.monthlyTarget2}
                target2Text={monthlySalesBarEndText2Template}
                z={dashboardValues?.monthlyTargetBonusCut2}
              />
            )}
        </Section>

        {incentiveMechanic === "monthlyPotWithDailyAddition" && (
          <>
            <SectionHeader>{copywriting.potProgressSection.title}</SectionHeader>
            <ProgressBar
              progress={dashboardValues?.monthlySales ?? 0}
              target={dashboardValues?.monthlyTarget ?? 0}
              end={dashboardValues?.monthlyTarget ?? 0}
              barHeight={0.8 * BAR_HEIGHT}
              startText={monthlySalesBarStartTextTemplate}
              endTopText={monthlySalesBarEndTextTemplate}
              includeTargetLine={false}
            />
            <EmojiFont>
              {dashboardValues?.isPotUnlocked
                ? copywriting.potProgressSection.potUnlocked.emoji
                : copywriting.potProgressSection.potNotUnlocked.emoji}
            </EmojiFont>
            <EmojiText>
              {dashboardValues?.isPotUnlocked
                ? copywriting.potProgressSection.potUnlocked.text
                : copywriting.potProgressSection.potNotUnlocked.text}
            </EmojiText>

            <SectionHeader>{copywriting.potSection.title}</SectionHeader>
            <Section>
              <SalesSummaryRow
                itemNameText={
                  copywriting.potSection.monthlyPotWithDailyAddition.todaysAdditionToPot
                }
                itemValueText={formatCurrency(
                  (dashboardValues?.additionPreTargetPortion ?? 0) +
                  (dashboardValues?.additionPostTargetPortion ?? 0)
                )}
                type={SalesSummaryRowType.headline}
              />
              <SalesSummaryRow
                itemNameText={copywriting.potSection.preTargetText(
                  dashboardValues?.dailyTargetCut,
                  dashboardValues?.dailyTarget
                )}
                itemValueText={formatCurrency(dashboardValues?.additionPreTargetPortion)}
                type={SalesSummaryRowType.grayText}
              />
              <SalesSummaryRow
                itemNameText={copywriting.potSection.postTargetText(
                  dashboardValues?.dailyTargetBonusCut,
                  dashboardValues?.dailyTarget
                )}
                itemValueText={formatCurrency(dashboardValues?.additionPostTargetPortion)}
                type={SalesSummaryRowType.grayText}
              />
            </Section>
            <Section>
              <SalesSummaryRow
                itemNameText={copywriting.potSection.monthlyPotWithDailyAddition.potSizeText}
                itemValueText={formatCurrency(dashboardValues?.potAfterAddition)}
                type={SalesSummaryRowType.grayText}
              />
              <SalesSummaryRow
                itemNameText={copywriting.potSection.staffCountText(incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "points")}
                itemValueText={dashboardValues?.numberToSplit?.toFixed(2) ?? "-"}
                type={SalesSummaryRowType.grayText}
              />
              <SalesSummaryRow
                itemNameText={copywriting.potSection.potRewardText(
                  incentiveMechanic,
                  dashboardValues?.isPotUnlocked ?? false,
                  incentiveSplitMechanic
                )}
                itemValueText={formatCurrency(dashboardValues?.potReward)}
                type={SalesSummaryRowType.blueBoldTextWSummaryLine}
              />
            </Section>
          </>
        )}

        {(incentiveMechanic === "monthlyPot" ||
          incentiveMechanic === "monthlyPot3Tier") && (
            <>
              <SectionHeader>{copywriting.potSection.title}</SectionHeader>
              <Section>
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.monthlyPot.title}
                  itemValueText={
                    dashboardValues?.isPotUnlocked
                      ? `${copywriting.potProgressSection.potUnlocked.text} ${copywriting.potProgressSection.potUnlocked.emoji}`
                      : `${copywriting.potProgressSection.potNotUnlocked.text} ${copywriting.potProgressSection.potNotUnlocked.emoji}`
                  }
                  type={SalesSummaryRowType.blueBoldText}
                />
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.monthlyPot.potSizeText(
                    dashboardValues?.isPotUnlocked
                  )}
                  itemValueText={formatCurrency(
                    (dashboardValues?.preTargetPortion ?? 0) +
                    (dashboardValues?.postTargetPortion ?? 0)
                  )}
                  type={SalesSummaryRowType.blueBoldTextWSummaryLine}
                />
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.preTargetText(
                    dashboardValues?.monthlyTargetCut,
                    dashboardValues?.monthlyTarget
                  )}
                  itemValueText={formatCurrency(dashboardValues?.preTargetPortion)}
                  type={SalesSummaryRowType.grayText}
                />
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.postTargetText(
                    dashboardValues?.monthlyTargetBonusCut,
                    dashboardValues?.monthlyTarget
                  )}
                  itemValueText={dashboardValues?.postTargetPortion2 > 0 ? "-" : formatCurrency(dashboardValues?.postTargetPortion)}
                  type={SalesSummaryRowType.grayText}
                />
                {incentiveMechanic === "monthlyPot3Tier" && (
                  <SalesSummaryRow
                    // itemNameText={`${formatPercentage(
                    //   dashboardValues?.monthlyTargetBonusCut2,
                    //   2
                    // )} of sales above ${dashboardValues?.monthlyTarget ? formatCurrency(dashboardValues?.monthlyTarget) : "$∞"}`}
                    itemNameText={copywriting.potSection.postTarget2Text(dashboardValues?.monthlyTargetBonusCut2, dashboardValues?.monthlyTarget)}
                    itemValueText={formatCurrency(dashboardValues?.postTargetPortion2)}
                    type={SalesSummaryRowType.grayText}
                  />
                )}
              </Section>
              <Section>
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.staffCountText(incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "points")}
                  itemValueText={dashboardValues?.numberToSplit?.toFixed(2) ?? "-"}
                  type={SalesSummaryRowType.grayText}
                />
                <SalesSummaryRow
                  itemNameText={copywriting.potSection.potRewardText(
                    incentiveMechanic,
                    dashboardValues?.isPotUnlocked ?? false,
                    incentiveSplitMechanic
                  )}
                  itemValueText={formatCurrency(dashboardValues?.potReward)}
                  type={SalesSummaryRowType.blueBoldTextWSummaryLine}
                />
              </Section>
            </>
          )}

        <SectionHeader>{copywriting.howItWorksSection.title}</SectionHeader>
        {incentiveMechanic === "monthlyPotWithDailyAddition" && (
          <ul>
            {copywriting.howItWorksSection
              .monthlyPotWithDailyAddition(
                dashboardValues?.dailyTargetCut,
                dashboardValues?.dailyTargetBonusCut
              )
              .map((copyLine, i) => (
                <li key={i}>{copyLine}</li>
              ))}
          </ul>
        )}
        {incentiveMechanic === "monthlyPot" && (
          <ul>
            {copywriting.howItWorksSection
              .monthlyPot(
                dashboardValues?.monthlyTargetCut,
                dashboardValues?.monthlyTargetBonusCut,
                selection?.venue === "Fico" ? 6 : 5
              )
              .map((copyLine, i) => (
                <li key={i}>{copyLine}</li>
              ))}
          </ul>
        )}
        {incentiveMechanic === "monthlyPot3Tier" && (
          <ul>
            {copywriting.howItWorksSection
              .monthlyPot3Tier(
                dashboardValues?.monthlyTargetCut,
                dashboardValues?.monthlyTargetBonusCut,
                dashboardValues?.monthlyTargetBonusCut2,
              )
              .map((copyLine, i) => (
                <li key={i}>{copyLine}</li>
              ))}
          </ul>
        )}
      </MobileLayout>
    </LoadingOverlay>
  );
};

export default IncentivesDashboardMobileMode;

const EmojiFont = styled.p`
  text-align: center;
  font-size: 36px;
  margin: 0px;
`;

const EmojiText = styled.p`
  color: #273561;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0px;
`;
