import {
  CONSOLIDATED,
  defaultHistoricSalesTrendViewOptions,
  defaultSalesProgressBarViewOptions,
  defaultVenueOptions,
} from "../constants/groupwideDashboard";
import { Dashboard, SalesSummaryRowType, Venue } from "../typings/common";
import {
  HeadlineText,
  Loading,
  MobileLayout,
  SectionHeader,
  SectionHeaderNoLine,
  SectionSubHeader,
  SeparatorText,
  VerticalSpacing,
} from "./StandardComponents";
import {
  HistoricSalesTrendViewOption,
  SalesProgressBarViewOption,
  SelectionProps,
  VenueOptions,
} from "../typings/groupwideDashboard";
import { formatCurrency, formatPercentage } from "../utils/currency";
import { toHumanDateFormatWithDow, toHumanDateTimeFormat } from "../utils/date";

import ComparativeData from "./ComparativeData.tsx";
import HcLineChart from "./HighCharts/HcLineChartTimeSeries";
import HorizontalToggle from "./HorizontalToggle";
import LoadingOverlay from "react-loading-overlay";
import MainHeader from "./MainHeader";
import ProgressBar from "./ProgressBarNew";
import React from "react";
import SalesSummaryRow from "./SalesSummaryRow";
import SelectFieldText from "./InputFields/SelectFieldText";
import SideBar from "./SideBarNew";
import computeConsolidated from "../utils/groupwideDashboard/computeConsolidated";
import { getAvailableVenues } from "../services/AirtablePlus";
import getVenueGroupwideSalesDashboardData from "../utils/groupwideDashboard/getVenueGroupwideSalesDashboardData";
import historicSalesToSeriesData from "../utils/groupwideDashboard/historicSalesToSeriesData";
import { loggedInUser } from "../utils/globals";
import moment from "moment";

const GroupwideSalesDashboard: React.FC = (props) => {
  const [selection, setSelection] = React.useState<SelectionProps>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSalesProgressBarView = (venueCode: string, selected: string) => {
    if (venueCode === CONSOLIDATED) {
      setSelection({
        ...selection,
        consolidated: {
          ...selection?.consolidated,
          salesProgressBarView: selected as SalesProgressBarViewOption,
        },
      });
    } else {
      if (selection?.venues) {
        const newVenues = selection?.venues.map((venue) => ({
          ...venue,
          salesProgressBarView:
            venueCode === venue.code
              ? (selected as SalesProgressBarViewOption)
              : venue.salesProgressBarView,
        }));
        setSelection({
          ...selection,
          venues: newVenues,
        });
      }
    }
  };

  const handleSalesTrendView = (venueCode: string, selected: string) => {
    if (venueCode === CONSOLIDATED) {
      setSelection({
        ...selection,
        consolidated: {
          ...selection?.consolidated,
          historicSalesTrendView: selected as HistoricSalesTrendViewOption,
        },
      });
    } else {
      if (selection?.venues) {
        const newVenues = selection.venues.map((venue) => ({
          ...venue,
          historicSalesTrendView:
            venueCode === venue.code
              ? (selected as HistoricSalesTrendViewOption)
              : venue.historicSalesTrendView,
        }));
        setSelection({
          ...selection,
          venues: newVenues,
        });
      }
    }
  };

  const handleVenueToggle = (key: string, active: boolean) => {
    let newVenues: (Venue & VenueOptions)[];
    if (selection?.venues) {
      if (key === "{ALL}") {
        newVenues = selection?.venues.map((venue) => ({
          ...venue,
          included: !active,
        })) as (Venue & VenueOptions)[];
      } else {
        newVenues = selection?.venues.map((venue) => ({
          ...venue,
          included: venue.code === key ? !active : venue.included,
        })) as (Venue & VenueOptions)[];
      }
      setSelection({
        ...selection,
        venues: newVenues,
      });
    }
  };

  const loadData = () => {
    setLoading(true);

    getAvailableVenues(Dashboard.groupwideSales, loggedInUser())
      .then((venueResponse) => {
        const venueDataPromises = venueResponse.map(
          (venue) => () => getVenueGroupwideSalesDashboardData(venue, moment())
        );

        return Promise.all(venueDataPromises.map((promise) => promise())).then(
          (venueDataResponses) => {
            console.log(venueDataResponses);
            return {
              venueResponse,
              venueDataResponses,
            };
          }
        );
      })
      .then(({ venueResponse, venueDataResponses }) => {
        const tempVenues = venueResponse.map(
          (venue, i) =>
          ({
            ...venue,
            ...defaultVenueOptions,
            data: venueDataResponses[i],
          } as Venue & VenueOptions)
        );

        setSelection({
          venues: tempVenues,
          consolidated: defaultVenueOptions,
        });

        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadData();
  }, []);

  // Update consolidated when venue selection changes
  React.useEffect(() => {
    const tempConsolidated = computeConsolidated(selection?.venues ?? []);

    setSelection({
      ...selection,
      consolidated: {
        ...selection?.consolidated,
        data: tempConsolidated,
      },
    });
  }, [selection?.venues]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          height: "100vh",
          position: "fixed",
        }),
      }}
    >
      <MobileLayout>
        <MainHeader />

        <SideBar selectedItem="Groupwide" />

        <SectionHeaderNoLine>{`Group-wide F&B daily sales report`}</SectionHeaderNoLine>

        <SectionSubHeader>
          Last updated:{" "}
          {selection?.consolidated?.data?.lastUpdated
            ? toHumanDateTimeFormat(selection.consolidated.data.lastUpdated)
            : "-"}
        </SectionSubHeader>

        <ComparativeData
          expandIntroText="Select venue(s) for consolidation"
          comparativeData={
            selection?.venues
              ? selection?.venues.map((venue) => ({
                key: venue.code,
                label: venue.name,
                active: venue.included ?? true,
              }))
              : []
          }
          comparativeDataChangeHandler={handleVenueToggle}
          allCheckText="All venues"
        />

        <VerticalSpacing height={20} />

        <SalesSummaryRow
          itemNameText="Net sales with service charge"
          itemValueText={formatCurrency(
            selection?.consolidated?.data?.dayNetSalesWithServiceCharge
          )}
          type={SalesSummaryRowType.blueBoldText}
          noGap={true}
        />
        <SectionSubHeader>
          for {toHumanDateFormatWithDow(moment().subtract(1, "day"))}
        </SectionSubHeader>

        <VerticalSpacing height={32} />

        <SelectFieldText
          id={CONSOLIDATED}
          options={defaultSalesProgressBarViewOptions}
          selected={selection?.consolidated?.salesProgressBarView ?? ""}
          changeHandler={handleSalesProgressBarView}
          displayTextTransformer={(selected) =>
            `${selected === "mtd" ? "MTD" : "YTD"} sales (actual vs budget) `
          }
        />

        <ProgressBar
          progress={
            selection?.consolidated?.salesProgressBarView === "mtd"
              ? selection?.consolidated?.data?.mtdSales ?? 50
              : selection?.consolidated?.data?.ytdSales ?? 50
          }
          end={
            selection?.consolidated?.salesProgressBarView === "mtd"
              ? selection?.consolidated?.data?.monthBudget ?? 100
              : selection?.consolidated?.data?.yearBudget ?? 100
          }
          includeTargetLine={false}
          startText={(progress, end) =>
            `${formatCurrency(progress)} (${formatPercentage(progress / end)})`
          }
          endBottomText={(progress, end) => `${formatCurrency(end)}`}
        />

        <VerticalSpacing height={20} />

        <HeadlineText>Historical sales trend</HeadlineText>

        <HorizontalToggle
          id={CONSOLIDATED}
          options={defaultHistoricSalesTrendViewOptions}
          selected={selection?.consolidated?.historicSalesTrendView ?? ""}
          changeHandler={handleSalesTrendView}
        />

        <HcLineChart
          seriesData={historicSalesToSeriesData(
            selection?.consolidated?.data?.historicSales ?? [],
            selection?.consolidated?.historicSalesTrendView ?? "daily"
          )}
          multipleTimePeriod
          height={320}
        />

        <VerticalSpacing height={12} />

        <SeparatorText>SALES SUMMARY BY VENUE</SeparatorText>

        {selection?.venues &&
          selection?.venues.map((venue) => (
            <>
              <SectionHeader>
                {venue.name}
                {/* {ConceptLogo[venue.code]("thumbnail")}  */}
              </SectionHeader>
              <SectionSubHeader>
                Last updated:{" "}
                {venue?.data?.lastUpdated
                  ? typeof venue.data.lastUpdated === "string"
                    ? venue.data.lastUpdated
                    : toHumanDateTimeFormat(venue.data.lastUpdated)
                  : "-"}
              </SectionSubHeader>

              <VerticalSpacing height={24} />

              <SalesSummaryRow
                itemNameText="Net sales with service charge"
                itemValueText={formatCurrency(venue.data?.dayNetSalesWithServiceCharge)}
                type={SalesSummaryRowType.blueBoldText}
                noGap={true}
              />
              <SectionSubHeader>
                for {toHumanDateFormatWithDow(moment().subtract(1, "day"))}
              </SectionSubHeader>

              <VerticalSpacing height={32} />

              <SelectFieldText
                id={venue.code}
                options={defaultSalesProgressBarViewOptions}
                selected={venue.salesProgressBarView ?? ""}
                changeHandler={handleSalesProgressBarView}
                displayTextTransformer={(selected) =>
                  `${selected === "mtd" ? "MTD" : "YTD"} sales (actual vs budget) `
                }
              />

              <ProgressBar
                progress={
                  venue.salesProgressBarView === "mtd"
                    ? venue.data?.mtdSales ?? 50
                    : venue.data?.ytdSales ?? 50
                }
                end={
                  venue.salesProgressBarView === "mtd"
                    ? venue.data?.monthBudget ?? 100
                    : venue.data?.yearBudget ?? 100
                }
                includeTargetLine={false}
                startText={(progress, end) =>
                  `${formatCurrency(progress)} (${formatPercentage(progress / end)})`
                }
                endBottomText={(progress, end) => `${formatCurrency(end)}`}
              />

              <VerticalSpacing height={20} />

              <HeadlineText>Historical sales trend</HeadlineText>

              <HorizontalToggle
                id={venue.code}
                options={defaultHistoricSalesTrendViewOptions}
                selected={venue.historicSalesTrendView ?? ""}
                changeHandler={handleSalesTrendView}
              />

              <HcLineChart
                seriesData={historicSalesToSeriesData(
                  venue.data?.historicSales ?? [],
                  venue?.historicSalesTrendView ?? "daily"
                )}
                multipleTimePeriod
                height={320}
              />

              <VerticalSpacing height={12} />
            </>
          ))}
      </MobileLayout>
    </LoadingOverlay>
  );
};

export default GroupwideSalesDashboard;
