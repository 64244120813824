// This is for the consolidation of The Coconut Club (Ann Siang)'s, The Coconut Club (Beach Road)'s,
// and The Coconut Club (Siglap)'s figures in IncentivesDashboard

import { DailyIncentiveLog, Dashboard } from "../typings/common";
import {
  aggregateMtdSalesForIncentiveCalc,
  computeMtdEndState,
  monthDailyRowsToMtdSalesForIncentiveCalc,
} from "./incentive";

import { Moment } from "moment";
import { airtableRequiredFields } from "./globals";
import { getDailyRowPeriod } from "../services/DataSourceRailroadSwitch";
import { getIncentiveAdminMonthlyParams } from "../components/IncentivesDashboard/IncentivesController";

export const isTccIncentiveConsolidationLogicActive = true;

export const cca = "The Coconut Club (Ann Siang)";
export const ccb = "The Coconut Club (Beach Road)";
export const ccs = "The Coconut Club (Siglap)";
export const ccr = "The Coconut Club (River Valley)";

export const getIncentivesDashboardValsForTcc = (date: Moment): Promise<DailyIncentiveLog> => {
  const monthStart = date.clone().startOf("month");

  // Get sales data for CCS from Airtable
  const ccsSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccs,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get sales data for CCB from Airtable
  const ccbSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccb,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get sales data for CCR from Airtable
  const ccrSalesPromise = () =>
    getDailyRowPeriod(
      monthStart,
      date,
      ccr,
      airtableRequiredFields.RawData.netSalesWithServiceChargeCalc
    );

  // Get incentive params
  const incentiveStructureParamsPromise = () =>
    getIncentiveAdminMonthlyParams(ccb, date.year(), date.month() + 1, false);

  return Promise.all([
    ccsSalesPromise(),
    ccbSalesPromise(),
    ccrSalesPromise(),
    incentiveStructureParamsPromise(),
  ]).then(
    ([ccsSalesResponse, ccbSalesResponse, ccrSalesResponse, incentiveStructureParamsResponse]) => {
      const tccCombinedSales = aggregateMtdSalesForIncentiveCalc(
        [
          monthDailyRowsToMtdSalesForIncentiveCalc(ccsSalesResponse, date),
          monthDailyRowsToMtdSalesForIncentiveCalc(ccbSalesResponse, date),
          monthDailyRowsToMtdSalesForIncentiveCalc(ccrSalesResponse, date),
        ],
        date
      );

      const mtdDailyIncentiveLog = computeMtdEndState(
        tccCombinedSales,
        incentiveStructureParamsResponse,
        date
      );

      const preTargetPortion =
        Math.min(
          incentiveStructureParamsResponse.monthlyTarget ?? 0,
          mtdDailyIncentiveLog.monthlySales ?? 0
        ) * (incentiveStructureParamsResponse.monthlyTargetCut ?? 0);

      const postTargetPortion2 =
        incentiveStructureParamsResponse.monthlyTarget2 && mtdDailyIncentiveLog.isPotUnlocked
          ? Math.max(
              0,
              (mtdDailyIncentiveLog.monthlySales ?? 0) -
                (incentiveStructureParamsResponse.monthlyTarget2 ?? 0)
            ) * (incentiveStructureParamsResponse.monthlyTargetBonusCut2 ?? 0)
          : 0;

      const postTargetPortion =
        mtdDailyIncentiveLog.isPotUnlocked && postTargetPortion2 === 0
          ? (incentiveStructureParamsResponse.monthlyTargetBonusCut ?? 0) *
            (Math.min(
              mtdDailyIncentiveLog.monthlySales ?? 0,
              incentiveStructureParamsResponse.monthlyTarget2 ?? Number.MAX_VALUE
            ) -
              (incentiveStructureParamsResponse.monthlyTarget ?? 0))
          : 0;

      const potReward =
        (preTargetPortion + postTargetPortion + postTargetPortion2) /
        (incentiveStructureParamsResponse.numberToSplit ?? 1);

      const output = {
        ...incentiveStructureParamsResponse,
        ...mtdDailyIncentiveLog,
        preTargetPortion,
        postTargetPortion,
        postTargetPortion2,
        potReward,
      };

      console.log(output);
      return output;
    }
  );
};
