import React from "react";
import styled from "styled-components";
import MainHeader from "./MainHeader";
import SideBar from "./SideBarNew";
import { MobileLayout } from "./StandardComponents";

const ManualAdjustmentsRequestPage: React.FC = (props) => {
  return (
    <MobileLayout>
      <MainHeader />

      <SideBar selectedItem="ManualAdjustmentRequest" />

      <StyledIframe
        className="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shrOgH5U1ww4EHbNr?backgroundColor=cyan"
        frameBorder="0"
      ></StyledIframe>
    </MobileLayout>
  );
};

const StyledIframe = styled.iframe`
  height: 100%;
  width: 520px;
  font-size: 12px;
  padding: 12px 0px;
`;

export default ManualAdjustmentsRequestPage;
