import {
  ChangesSavedStatusIndicator,
  DesktopLayout,
  FailedAlert,
  FlexRow,
  GrayPanel,
  HeadlineText,
  Left,
  Loading,
  Right,
  SaveButton,
  SectionHeaderNew as SectionHeader,
  Separator,
  Text as ogText,
} from "./StandardComponents";
import {
  Dashboard,
  IncentiveAdminMonthlyParams,
  IncentiveMechanic,
  IncentiveSplitMechanic,
  Occasion,
  TlbgConcept,
  Venue,
  YearMonth,
} from "../typings/common";
import {
  getAvailableVenues,
  getOccasions,
  getVenueIncentiveMechanic,
} from "../services/AirtablePlus";
import {
  getIncentiveAdminMonthlyParams,
  pushIncentiveAdminQuery,
} from "./IncentivesDashboard/IncentivesController";
import { loggedInUser, percentageValueTransformer } from "../utils/globals";
import {
  fromToMoment,
  toHumanDateFormatWoYear,
  toHumanDateTimeFormat,
  toHumanMonthYearString,
} from "../utils/date";

import IncentivesDashboardHeader from "./IncentivesDashboardHeader";
import LineTextField from "./InputFields/LineTextField";
import LoadingOverlay from "react-loading-overlay";
import MainHeader from "./MainHeader";
import React from "react";
import ValueCalendar from "./SalesTargetCalendar";
import moment from "moment";
import styled from "styled-components";

const SectionWrapper = styled.div`
  margin: 12px 0px;
`;

const Text = styled(ogText)`
  margin: 4px 0px;
`;

const ObservanceText = styled.p`
  color: #646c77;
  font-size: 14px;
  text-align: left;
  margin: 8px 0px;
`;

const BottomRightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type IncentiveAdminProps = {
  venue?: string;
  year: number;
  month: number;
};

const IncentivesAdmin: React.FC<IncentiveAdminProps> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [availableOptions, setAvailableOptions] = React.useState<{
    venues: Venue[];
    months: YearMonth[];
  }>();
  const [paramsData, setParamsData] = React.useState<IncentiveAdminMonthlyParams>();
  // When page is initially loaded, paramsData should be the same as databaseParamsData
  // It will only be different after user changes the values (i.e. paramsData)
  // databaseParamsData is always pulled from database
  const [holidays, setHolidays] = React.useState<Occasion[]>();
  const [observances, setObservances] = React.useState<Occasion[]>();
  const [selection, setSelection] = React.useState<IncentiveAdminProps>({
    venue: "",
    year: moment().year(),
    month: moment().month() + 1,
  });
  const [changesSavedStatus, setChangesSavedStatus] = React.useState<boolean>(true);
  const [showUnsavedWarning, setShowUnsavedWarning] = React.useState<boolean>(false);
  const [incentiveMechanic, setIncentiveMechanic] = React.useState<IncentiveMechanic>();
  const [incentiveSplitMechanic, setIncentiveSplitMechanic] =
    React.useState<IncentiveSplitMechanic>();

  const handleDateChange = (yearMonthString: string) => {
    const date = moment(yearMonthString, "YYYY-MM");

    setSelection({
      ...selection,
      year: date.year(),
      month: date.month() + 1,
    });
  };

  const salesTargetCalendarChangeHandler = (id: number, input: number) => {
    let tempDailyTargets = paramsData?.dailyTargets;
    if (tempDailyTargets) {
      tempDailyTargets[id] = input;
      setParamsData({
        ...paramsData,
        dailyTargets: tempDailyTargets,
      });
    }
    setChangesSavedStatus(false);
  };

  const monthlyParamsChangeHandler = (id: string, input: number) => {
    console.log(input);
    if (paramsData) {
      setParamsData({
        ...paramsData,
        [id as keyof IncentiveAdminMonthlyParams]: input,
      });
    }
    setChangesSavedStatus(false);
  };

  const saveHandler = () => {
    if (paramsData !== undefined) {
      setLoading(true);
      pushIncentiveAdminQuery(
        {
          ...paramsData,
          venue: selection.venue,
          year: selection.year,
          month: selection.month,
        },
        incentiveMechanic,
        loggedInUser() ?? undefined
      ).then((res) => {
        if (res.rowsAddAttempt === res.rowsAdded && res.rowsUpdateAttempt === res.rowsUpdated) {
          setChangesSavedStatus(true);
          setShowUnsavedWarning(false);
        } else {
          setShowUnsavedWarning(true);
        }
        setLoading(false);
      });
    } else {
      console.log("paramsData is undefined");
    }
  };

  async function fetchOptions(): Promise<void> {
    const availableVenueResponse = await getAvailableVenues(
      Dashboard.incentivesAdmin,
      loggedInUser() ?? undefined
    );
    const threeMonthsAgo = moment().subtract(3, "months");
    const twelveMonthsAhead = moment().add(12, "months");
    const availableMonths = fromToMoment(threeMonthsAgo, twelveMonthsAhead, 1, "month").map((val) => ({
      year: val.year(),
      month: val.month() + 1,
    }));

    const availableOptionsTemp = {
      venues: availableVenueResponse,
      months: availableMonths,
    };

    setAvailableOptions(availableOptionsTemp);
    setLoading(false);
  }

  const monthYearString =
    selection?.year && selection?.month
      ? toHumanMonthYearString(
        moment()
          .year(selection.year)
          .month(selection.month - 1)
      )
      : "";

  React.useEffect(() => {
    setLoading(true);
    const defaultVenue = (props as any).location.state?.defaultVenue;

    if (defaultVenue) {
      setSelection({
        venue: defaultVenue,
        year: moment().year(),
        month: moment().month() + 1,
      });
    }

    fetchOptions();
  }, []);

  React.useEffect(() => {
    if (selection && selection.venue && selection.year && selection.month) {
      setLoading(true);

      const incentiveAdminMonthlyParamsPromise = getIncentiveAdminMonthlyParams(
        selection.venue,
        selection.year,
        selection.month
      );

      const occasionsPromise = getOccasions(selection.year, selection.month);

      const incentiveMechanicPromise = getVenueIncentiveMechanic(selection.venue);

      Promise.all([
        incentiveMechanicPromise,
        incentiveAdminMonthlyParamsPromise,
        occasionsPromise,
      ]).then(
        ([incentiveMechanicResponse, incentiveAdminMonthlyParamsResponse, occasionsResponse]) => {
          setIncentiveMechanic(incentiveMechanicResponse.incentiveMechanic);
          setIncentiveSplitMechanic(incentiveMechanicResponse.incentiveSplitMechanic);
          setParamsData(incentiveAdminMonthlyParamsResponse);

          const holidaysResponse = occasionsResponse.filter(
            (occasion) => occasion.type === "Holiday"
          );

          const observancesResponse = occasionsResponse.filter(
            (occasion) => occasion.type === "Observance"
          );

          setHolidays(holidaysResponse);
          setObservances(observancesResponse);
          setLoading(false);
        }
      );
    }
  }, [selection]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={Loading}
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: "rgba(100, 108, 119, 0.2)",
          zIndex: 2000,
          display: "flex",
          flex: 1,
          alignItems: "stretch",
          overflow: "hidden",
        }),
      }}
    >
      <Wrapper>
        <IncentivesDashboardHeader
          venueOptions={availableOptions?.venues}
          venueChangeHandler={(selectedVenue) =>
            setSelection({
              ...selection,
              venue: selectedVenue,
            })
          }
          venueSelected={selection?.venue}
          venuePlaceholder="Select venue"
          dateType="month"
          monthChangeHandler={handleDateChange}
          monthOptions={availableOptions?.months}
          monthSelected={{
            year: selection.year,
            month: selection.month,
          }}
          menuOuterContainerId="outer-container"
          menuSelectedItem="IncentivesAdmin"
          outerContainerId="outer-container"
          context={Dashboard.incentivesAdmin}
        />

        <DesktopLayout scrollable={true}>
          <FlexRow marginBottom={16}>
            <Left zIndex={1001}>
              <HeadlineText>Sales incentive parameters</HeadlineText>
            </Left>
            <MainHeader secondaryLogo={selection?.venue as TlbgConcept} isFixed={false} />
            <Right zIndex={1001}>
              <Text>
                Last updated:{" "}
                {paramsData?.lastUpdated
                  ? toHumanDateTimeFormat(paramsData?.lastUpdated)
                  : "(Not updated)"}
              </Text>
            </Right>
          </FlexRow>
          {showUnsavedWarning && (
            <FailedAlert>Error saving your changes. Please contact administrator.</FailedAlert>
          )}
          <FlexRow>
            <GrayPanel flex={1}>
              <SectionHeader>Monthly parameters - {monthYearString}</SectionHeader>

              <SectionWrapper>
                <LineTextField
                  fieldId={"daysInMonth"}
                  fieldName="Days in month"
                  fieldValue={moment()
                    .year(selection.year)
                    .month(selection.month - 1)
                    .daysInMonth()}
                  editable={false}
                />

                <LineTextField
                  fieldId={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? "dailyTargetCut"
                      : "monthlyTargetCut"
                  }
                  fieldName="X"
                  fieldValueSuffix="&nbsp;%"
                  fieldValue={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? paramsData?.dailyTargetCut
                      : paramsData?.monthlyTargetCut
                  }
                  valueTransformer={percentageValueTransformer}
                  fieldHint={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? "When the daily sales target is reached, X% of the daily sales target will be added to the pot"
                      : "When the monthly sales target is reached, X% of the monthly sales target will be added to the pot"
                  }
                  changeHandler={monthlyParamsChangeHandler}
                />

                <LineTextField
                  fieldId={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? "dailyTargetBonusCut"
                      : "monthlyTargetBonusCut"
                  }
                  fieldName="Y"
                  fieldValueSuffix="&nbsp;%"
                  fieldValue={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? paramsData?.dailyTargetBonusCut
                      : paramsData?.monthlyTargetBonusCut
                  }
                  valueTransformer={percentageValueTransformer}
                  fieldHint={
                    incentiveMechanic === "monthlyPotWithDailyAddition"
                      ? "When the daily sales target is reached, Y% of the excess above the daily sales target will be added to the pot"
                      : incentiveMechanic === "monthlyPot3Tier"
                        ? "When the monthly sales target is reached, Y% of the excess above the monthly sales target (lower bound) will be added to the pot, capped at the monthly sales target (upper bound)"
                        : "When the monthly sales target is reached, Y% of the excess above the monthly sales target will be added to the pot"
                  }
                  changeHandler={monthlyParamsChangeHandler}
                />

                {incentiveMechanic === "monthlyPot3Tier" && (
                  <LineTextField
                    fieldId="monthlyTargetBonusCut2"
                    fieldName="Z"
                    fieldValueSuffix="&nbsp;%"
                    fieldValue={paramsData?.monthlyTargetBonusCut2}
                    valueTransformer={percentageValueTransformer}
                    fieldHint={
                      "When the monthly sales target is reached, Z% of the excess above the monthly sales target (upper bound) will be added to the pot"
                    }
                    changeHandler={monthlyParamsChangeHandler}
                  />)}

                <LineTextField
                  fieldId={"numberToSplit"}
                  fieldName={`Number of ${incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "point"
                    }`}
                  fieldValue={paramsData?.numberToSplit}
                  fieldHint={`The number of ${incentiveSplitMechanic === IncentiveSplitMechanic.BY_STAFF ? "staff" : "point"
                    } that the eventual pot will be split amongst`}
                  changeHandler={monthlyParamsChangeHandler}
                />

                <LineTextField
                  fieldId={"monthlyTarget"}
                  fieldName={`Monthly target${incentiveMechanic === "monthlyPot3Tier" ? " (lower bound)" : ""}`}
                  fieldValuePrefix="$"
                  fieldValue={paramsData?.monthlyTarget}
                  fieldHint="The target to meet before the pot is unlocked"
                  changeHandler={monthlyParamsChangeHandler}
                />


                {incentiveMechanic === "monthlyPot3Tier" && (
                  <LineTextField
                    fieldId={"monthlyTarget2"}
                    fieldName={"Monthly target (upper bound)"}
                    fieldValuePrefix="$"
                    fieldValue={paramsData?.monthlyTarget2}
                    fieldHint="The target to meet before Z% multiplier is unlocked"
                    changeHandler={monthlyParamsChangeHandler}
                  />
                )}
              </SectionWrapper>

              <Separator />

              <SectionWrapper>
                {holidays && holidays?.length > 0 && (
                  <>
                    <HeadlineText>Public holidays in {monthYearString}</HeadlineText>

                    {holidays
                      .sort((holidayA, holidayB) => holidayA.date.diff(holidayB.date))
                      .map((holiday) => (
                        <ObservanceText key={holiday.occasionName}>
                          {`${toHumanDateFormatWoYear(holiday.date)}: ${holiday.occasionName}`}
                        </ObservanceText>
                      ))}
                  </>
                )}

                {observances && observances.length > 0 && (
                  <>
                    <HeadlineText>Observances in {monthYearString}</HeadlineText>

                    {observances
                      .sort((observanceA, observanceB) => observanceA.date.diff(observanceB.date))
                      .map((observance) => (
                        <ObservanceText key={`${observance.occasionName}(${observance.date})`}>
                          {`${toHumanDateFormatWoYear(observance.date)}: ${observance.occasionName
                            }`}
                        </ObservanceText>
                      ))}
                  </>
                )}
              </SectionWrapper>
            </GrayPanel>
            <GrayPanel flex={2}>
              <SectionHeader>Daily sales targets - {monthYearString}</SectionHeader>

              <ValueCalendar
                year={selection.year}
                month={selection.month}
                changeHandler={salesTargetCalendarChangeHandler}
                observances={observances}
                holidays={holidays}
                dailyValues={paramsData?.dailyTargets}
                disabled={(_dayIndex) => incentiveMechanic === "monthlyPot" || incentiveMechanic === "monthlyPot3Tier"}
              />
            </GrayPanel>
          </FlexRow>

          <BottomRightSection>
            <ChangesSavedStatusIndicator status={changesSavedStatus} />

            <SaveButton onClick={saveHandler}>Save</SaveButton>
          </BottomRightSection>
        </DesktopLayout>
      </Wrapper>
    </LoadingOverlay>
  );
};

export default IncentivesAdmin;
