import moment, { Moment } from "moment";

import { FlattenUnion } from "../utils/globals";
import { LineSeriesPoint } from "react-vis";

export type DailyRow = {
  // [key: string]: string
  venue?: string;
  business_date?: string;
  day_of_week?: Dow;
  end_time?: string;
  service_charge?: number;
  // Service Charge = Revenue service charge (10%)
  "tips|amount"?: number;
  "tips|count"?: number;
  gst?: number;
  total_collection?: number;
  discounts?: number;
  average_table_time?: number;
  "channel|dine_in|sales"?: number;
  "channel|dine_in|guests"?: number;
  "channel|dine_in|check_count"?: number;
  "channel|take_out|sales"?: number;
  "channel|take_out|guests"?: number;
  "channel|take_out|check_count"?: number;
  "channel|delivery|sales"?: number;
  "channel|delivery|guests"?: number;
  "channel|delivery|check_count"?: number;
  "channel|events|sales"?: number;
  "channel|events|guests"?: number;
  "channel|events|check_count"?: number;
  "channel|others|sales"?: number;
  "channel|others|guests"?: number;
  "channel|others|check_count"?: number;
  "product|food|sales"?: number;
  "product|beverage|sales"?: number;
  "product|condiment|sales"?: number;
  "product|miscellaneous|sales"?: number;

  "delivery|deliveroo|sales"?: number;
  "delivery|deliveroo|order_count"?: number;
  "delivery|foodpanda|sales"?: number;
  "delivery|foodpanda|order_count"?: number;
  "delivery|grabfood|sales"?: number;
  "delivery|grabfood|order_count"?: number;
  "delivery|oddle|sales"?: number;
  "delivery|oddle|order_count"?: number;

  "delivery|deliveroo|sales_less_svc"?: number;
  "delivery|foodpanda|sales_less_svc"?: number;
  "delivery|grabfood|sales_less_svc"?: number;
  "delivery|oddle|sales_less_svc"?: number;

  "mealtime|breakfast|sales"?: number;
  "mealtime|breakfast|guests"?: number;
  "mealtime|breakfast|check_count"?: number;
  "mealtime|lunch|sales"?: number;
  "mealtime|lunch|guests"?: number;
  "mealtime|lunch|check_count"?: number;
  "mealtime|tea|sales"?: number;
  "mealtime|tea|guests"?: number;
  "mealtime|tea|check_count"?: number;
  "mealtime|happy_hour|sales"?: number;
  "mealtime|happy_hour|guests"?: number;
  "mealtime|happy_hour|check_count"?: number;
  "mealtime|after_night|sales"?: number;
  "mealtime|after_night|guests"?: number;
  "mealtime|after_night|check_count"?: number;
  "mealtime|misc|sales"?: number;
  "mealtime|misc|guests"?: number;
  "mealtime|misc|check_count"?: number;

  // Calculated (internal data)
  net_sales_with_service_charge?: number;
  net_sales?: number;
  gross_sales?: number;
  average_spend_per_guest?: number;
  "channel|dine_in|aov"?: number;
  "channel|take_out|aov"?: number;
  "channel|delivery|aov"?: number;
  "channel|events|aov"?: number;
  "channel|others|aov"?: number;
  "dine_in_w_svc|sales"?: number;

  "delivery|deliveroo|aov"?: number;
  "delivery|foodpanda|aov"?: number;
  "delivery|grabfood|aov"?: number;
  "delivery|oddle|aov"?: number;

  // Calculated (external data)
  // Sales = Net sales with service charge

  // salesHistoryRecent: Needed for
  // (1) l6wDowSales
  // (2) ttlmMtdSales
  // (3) salesTrendL6w

  // salesHistoryLastYear: Needed for
  // (1) tmlyDowSales
  // (2) tmlyMtdSales
  // (3) salesTrendL6wLy

  sumChannelSales?: number;

  // Used when dailyRow in question is
  // a child item of salesHistoryRecent
  // as an indicator as to whether respective aggregated values
  // should include dailyRow in question
  includedInSalesHistory?: {
    l6wDowSales?: boolean;
    tmlyDowSales?: boolean;
    ttlmMtdSales?: boolean;
    tmlyMtdSales?: boolean;
    salesTrendL6w?: boolean;
    salesTrendL6wLy?: boolean;
    mtdSales?: boolean;
    toggle?: boolean;
  };

  includedInMarketingHistory?: {
    selectedPeriod?: boolean;
  };

  dtaSales?: number;
  dtaOrderCount?: number;
  dtaAov?: number;

  deaSales?: number;
  deaGuestCount?: number;
  deaSpendPerGuest?: number;

  last_modified?: string;
  last_updated?: Moment;
};

export type SalesHistoryType =
  | "l6wDowSales"
  | "tmlyDowSales"
  | "ttlmMtdSales"
  | "tmlyMtdSales"
  | "salesTrendL6w"
  | "salesTrendL6wLy"
  | "mtdSales"
  | "toggle";

export type AirtableSelectParams = {
  fields?: string[];
  filterByFormula?: string;
  maxRecords?: number;
  // pageSize: number,
  sort?: AirtableSelectParamsSort[];
  view?: string;
  // cellFormat: "json" | "string",
  // timeZone: string,
  // userLocale: string
};

export type AirtableSelectParamsSort = {
  field: string;
  direction: "asc" | "desc";
};

export type AirtableUpdateParams = {
  where?: string;
  rowId?: string;
  data: {
    [key: string]: any;
  };
};

export type GoogleCredential = {
  installed: {
    [key: string]: string;
  };
};

export interface Response<T> {
  meta: PaginationParams;
  results: Array<T>;
}

export type PaginationParams = {
  page: number;
  limit: number;
  found: number;
};

export type Option = {
  value?: string;
  label?: string;
};

export enum SalesSummaryRowType {
  standard = "STANDARD",
  headline = "HEADLINE",
  grayText = "GRAY_TEXT",
  grayBoldText = "GRAY_BOLD_TEXT",
  blueBoldTextWSummaryLine = "BLUE_BOLD_TEXT_W_SUMMARY",
  blueBoldText = "BLUE_BOLD_TEXT",
}

export type DeltaProps = {
  deltaDirection: "+" | "-" | "0";
  deltaText: string;
};

export type TrendlineSeriesData = {
  trendName: string;
  trendType: "LineSeries" | "VerticalBarSeries";
  className?: string;
  data: Array<LineSeriesPoint>;
};

export type PieData = {
  name: string;
  value: number;
  color?: string;
};

// Day of week
export type Dow = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

export type PullHistoricProps = {
  venue: string;
  date: string;
};

export type PullHistoricPropsDateRange = {
  venue: string;
  fromDate: string;
  toDate: string;
};

export enum IncentiveSplitMechanic {
  BY_STAFF = "byStaff",
  BY_POINT = "byPoint",
}

export type Venue = {
  name: string;
  code: string;
  combined_entity_name?: string;
  accessible_users?: string[];
  incentive_mechanic?: string;
  incentive_split_mechanic?: IncentiveSplitMechanic;
  data_source?: VenueDataSource;
  data_source_day_one?: string;
  venue_leaders?: User[];
  venue_leaders_facebook_workplace_id?: string[];
};

export type VenueDataSource = "airtable" | "lightspeed" | "revel";

export type IncentiveMechanic = "monthlyPotWithDailyAddition" | "monthlyPot" | "monthlyPot3Tier";

export type SalesHistoryRecent = {
  l6wDowSales?: DailyRow[];
  ttlmMtdSales?: DailyRow[];
  salesTrendL6w?: DailyRow[];
  mtdSales?: DailyRow[];
};

export type SalesHistoryLastYear = {
  tmlyDowSales?: DailyRow[];
  tmlyMtdSales?: DailyRow[];
  salesTrendL6wLy?: DailyRow[];
};

export type ChannelIncDel = {
  label: string;
  code:
    | Channel["code"]
    | "delivery|oddle"
    | "delivery|grabfood"
    | "delivery|deliveroo"
    | "delivery|foodpanda";
  codeSales:
    | ChannelCodeSales
    | "delivery|oddle|sales_less_svc"
    | "delivery|grabfood|sales_less_svc"
    | "delivery|deliveroo|sales_less_svc"
    | "delivery|foodpanda|sales_less_svc";
  codeOrderCount:
    | ChannelCodeOrderCount
    | "delivery|oddle|order_count"
    | "delivery|grabfood|order_count"
    | "delivery|deliveroo|order_count"
    | "delivery|foodpanda|order_count";
  active: boolean;
};

export type Channel = {
  label: string;
  code: "dine_in" | "take_out" | "delivery" | "events" | "others";
  codeSales: ChannelCodeSales;
  codeOrderCount: ChannelCodeOrderCount;
  codeGuestCount: ChannelCodeGuestCount;
  active: boolean;
};

export type ChannelCodeSales =
  | "channel|dine_in|sales"
  | "channel|take_out|sales"
  | "channel|delivery|sales"
  | "channel|events|sales"
  | "channel|others|sales"
  | "dine_in_w_svc|sales";

export type ChannelCodeOrderCount =
  | "channel|dine_in|check_count"
  | "channel|take_out|check_count"
  | "channel|delivery|check_count"
  | "channel|events|check_count"
  | "channel|others|check_count";

export type ChannelCodeGuestCount =
  | "channel|dine_in|guests"
  | "channel|take_out|guests"
  | "channel|delivery|guests"
  | "channel|events|guests"
  | "channel|others|guests";

export type ChannelSales = {
  name: string;
  value: number;
  percentageOfTotalSales?: number;
};

export type ProductType = "food" | "beverage" | "condiment" | "miscellaneous";

export type ProductSales = ChannelSales;

export type PermissionsDashboard =
  | "Sales"
  | "Marketing"
  | "Incentives"
  | "IncentivesAdmin"
  | "SalesDataInput"
  | "Administrator"
  | "GodMode"
  | "DataAssurance"
  | "Groupwide"
  | "RecognitionForm"
  | "OpenArmsRecognition"
  | "ManualAdjustmentRequest"
  | "ManualAdjustments"
  | "ManualApiRefresh";

export type Page = PermissionsDashboard | "SetPassword" | "Changelog" | "Login";

export type User = {
  username?: string;
  permissions_dashboard?: PermissionsDashboard[];
  permissions_venue?: Venue[];
  password_hash?: string;
  password_raw?: string;
  default_redirect?: string;
  default_venue_name?: string[];
  active?: boolean;
};

export type Budget = {
  venue?: string;
  year?: number;
  month?: number;
  budget?: number;
};

export type Bug = {
  bugType?: string;
  bugDescription?: string;
  date?: Moment;
};

export type AirtableError = "Field error" | "More than one user" | "No user found";

export type FacebookLoginIdentity = {
  accessToken?: string;
  data_access_expiration_time?: number;
  expiresIn?: number;
  graphDomain?: string;
  id?: string;
  name?: string;
  signedRequest?: string;
  userId?: string;
};

export type FacebookAdsCampaignMetrics = {
  id?: string;
  name?: string;
  amountSpent?: number;
  results?: FacebookAdsCampaignResult[];
};

export type FacebookAdsCampaignResult = {
  actionType: string;
  value: number;
  costPerValue: number;
};

export type AggregationFunction = "sum" | "average" | "last";

// export type TlbgConcept = keyof TlbgConceptCode

export type TlbgConcept =
  | "Clink Clink"
  | "Esora"
  | "Extra Virgin Pizza"
  | "Le Bon Funk (Club Street)"
  | "Loof"
  | "Odette"
  | "Over Easy Fullerton"
  | "Over Easy Orchard"
  | "Straits Clan"
  | "Tanjong Beach Club"
  | "The Black Swan"
  | "The White Rabbit"
  | "The Red Dragon"
  | "Grab Kitchen"
  | "The Coconut Club (Siglap)"
  | "Claudine"
  | "The Coconut Club (Ann Siang)"
  | "The Coconut Club (Beach Road)"
  | "Le Bon Funk (Holland Village)";

export type DashboardType =
  | Dashboard.sales
  | Dashboard.marketing
  | Dashboard.assurance
  | Dashboard.incentives
  | Dashboard.incentivesAdmin
  | Dashboard.groupwideSales
  | Dashboard.salesInput;

export enum Dashboard {
  sales = "sales_dashboard",
  marketing = "marketing_dashboard",
  assurance = "assurance_dashboard",
  incentives = "partners_programme",
  incentivesAdmin = "partners_programme_admin",
  groupwideSales = "groupwide_dashboard",
  salesInput = "sales_data_input",
}

export type DataAssuranceEntry = {
  dailySum: number;
  systemSum: number;
  // loading?: boolean,
  sumsTally?: boolean;
};

export type DataAssuranceCol = {
  year: number;
  assuredFromDate?: Moment;
  assuredToDate?: Moment;
  dataAssuranceEntries: {
    [key in keyof DailyRow]: DataAssuranceEntry;
  };
};

export type VenueAssuranceData = {
  lastYearData: DataAssuranceCol;
  yearToDateData: DataAssuranceCol;
  metrics: (keyof DailyRow)[];
  assuranceStatus: boolean; // True if all systemSum and dailySum tally
};

export type AssuranceData = {
  // key: concept code
  [key: string]: {
    venueName?: string;
    venueAssuranceData?: VenueAssuranceData;
  };
};

// export type AssuranceData = (
//   VenueAssuranceData &
//   {
//     venueName: string,
//     venueCode: string
//   }
// )[];

export type GitCommit = {
  commit?: {
    message?: string;
    author?: {
      date?: string;
      name?: string;
    };
  };
};

export type GitDailySummary = {
  [key: string]: string[];
};

export type YearlyRow = DailyRow & {
  business_date_from?: string;
  business_date_to?: string;
};

export enum AuthResponse {
  success = "SUCCESS", // Logged in with right privileges
  forbidden = "FORBIDDEN", // Not logged in
  unauthorised = "UNAUTHORISED",
}

export type RedirectProps = {
  redirectPath: string;
  defaultVenue?: string;
};

export type UserAuthDetails = {
  loggedInUser: string;
  redirectPath: string;
  defaultVenue: string;
};

export type IncentiveParam = {
  id?: string;
  venue?: string;
  year: number;
  month: number;
  day?: number;
  input_var_type: IncentiveParamsInputVarType;
  input_var_value: number;
  valid?: boolean;
  modified_by?: string;
};

export type IncentiveParamsInputVarType =
  | "dailyTarget"
  | "dailyTargetCut"
  | "dailyTargetBonusCut"
  | "numberToSplit"
  | "monthlyTarget"
  | "monthlyTargetCut"
  | "monthlyTargetBonusCut"
  | "monthlyTarget2"
  | "monthlyTargetBonusCut2";

export type IncentiveStructureParameters = {
  dailyTarget?: number;
  dailyTargets?: {
    [key: number]: number; // keys = dates (standard form)
  };
  wholeMonthDailyTargets?: {
    [key: string]: number; // keys = dates (standard form)
  };
  dailyTargetCut?: number; // X
  dailyTargetBonusCut?: number; // Y
  numberToSplit?: number;
  monthlyTarget?: number;
  monthlyTarget2?: number;
  monthlyTargetCut?: number;
  monthlyTargetBonusCut?: number;
  monthlyTargetBonusCut2?: number;
};

export type IncentiveVars = {
  lastUpdated?: Moment;
  dailySales?: number; // net sales with service charge
  salesExceedDailyTarget?: boolean;
  additionPreTargetPortion?: number;
  additionPostTargetPortion?: number;
  potBeforeAddition?: number;
  potAfterAddition?: number;
  monthlySales?: number;
  isPotUnlocked?: boolean;
  potReward?: number; // Hypothetical if pot is not yet unlocked

  preTargetPortion?: number;
  postTargetPortion?: number;
  postTargetPortion2?: number;
};

export type DailyIncentiveLog = FlattenUnion<IncentiveStructureParameters | IncentiveVars>;

export type DailyMetric = {
  [key: number]: number;
  // key: dayIndex; val: dailyTarget
};

export type IncentiveAdminMonthlyParams = {
  venue?: string;
  year?: number;
  month?: number;
  dailyTargets?: DailyMetric;
  dailyTargetCut?: number;
  dailyTargetBonusCut?: number;
  numberToSplit?: number;
  monthlyTarget?: number;
  monthlyTarget2?: number;
  lastUpdated?: Moment;
  monthlyTargetCut?: number;
  monthlyTargetBonusCut?: number;
  monthlyTargetBonusCut2?: number;
};

export type OccasionType = "Holiday" | "Observance";

export type Occasion = {
  date: Moment;
  occasionName: string;
  type: OccasionType;
};

export type DisplayType = "desktop" | "mobile";

export type ValueTransformer = {
  backendToFrontend: (arg0: number) => number;
  frontendToBackend: (arg0: number) => number;
};

export type DatabaseUpdateResponse = {
  rowsAddAttempt?: number;
  rowsAdded?: number;
  rowsUpdateAttempt?: number;
  rowsUpdated?: number;
  message?: string;
  payload?: any;
};

export type FieldAddUpdatePromiseProps = {
  // field: IncentiveParamsInputVarType | AdhocInputValueType; // To be extended accordingly
  toAdd: boolean; // true: add; false: update
  value: number;
  day?: number;
  recordIdToUpdate?: string;
  modifiedBy?: string;
};

export type YearMonth = {
  year: number;
  month: number;
};

export type QuarterLabelSeries = {
  monthIndices: number[];
};

export type LightspeedIntegrationData = {
  id?: string;
  dataType?: string;
  key?: string;
  value?: string;
  lastModified?: string;
};

export type LightspeedCredentials = {
  accessToken?: string;
  refreshToken?: string;
  expiry?: Moment;
};

export type TimePeriod = {
  from: Moment;
  to: Moment;
};

export type AdhocInputValueType = "netSalesWithServiceCharge";

export type AdhocDataInput = {
  id?: string;
  venue?: string;
  venueCode?: string;
  date?: string | Moment;
  valueType?: AdhocInputValueType;
  value?: number;
  lastUpdated?: string | Moment;
};

export type MtdSalesForIncentiveCalc = {
  currDaySales: number;
  mtdSales: number;
  dailySales: {
    [key: string]: number; // key: standard date string, val: daily sales
  };
};

export type RecognitionData = {
  // senderId?: string;
  // senderEmail?: string;
  timestamp?: Moment;
  employeeId?: string;
  employeeEmail?: string;
  message?: string;
  senderName?: string;
  sharePublicly?: boolean;
};

export type SnackbarProps = {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning";
};
